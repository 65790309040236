<template>
  <div>
    <div class="btn-back" @click="redirectHomePage">
      <i class="el-icon-arrow-left" style="font-size: 26px; font-weight: bolder"></i>
      <span>Quay lại danh sách vinh danh</span>
    </div>
    <div class="header-detail" v-if="type === 'staff'">
      <div style="display: flex; justify-content: space-between; align-items: center">
        <div style="display: flex; align-items: center; gap: 20px">
          <div class="img-staff-container">
            <img
                class="img-staff"
                :src="honorDetail?.image" alt=""
            />
          </div>
          <div class="header-right">
            <div>
              <h3 class="name-custom line-clamp-1">{{ honorDetail?.name }}</h3>
              <span class="desc-custom line-clamp-2">{{ honorDetail?.description }}</span>
              <h3 class="desc-custom line-clamp-1">{{ honorDetail?.apartment }}</h3>
            </div>
            <ActionComponent :post="honorDetail" type-post="staff"/>
          </div>
        </div>
      </div>
    </div>
    <div v-if="typeDetail === 'okr'" class="header-okr">
      <div class="header-okr__left">
        <div style="display: flex; align-items: start; flex-direction: column;">
          <span class="name-custom line-clamp-2">{{ honorDetail?.name }}</span>
          <span class="desc-custom line-clamp-3">{{ honorDetail?.description }}</span>
        </div>
        <ActionComponent :post="honorDetail" type-post="okr"/>
      </div>
      <div style="position: relative">
        <img class="img-okr" :src="honorDetail?.image" alt="" />
        <div class="header-blur"></div>
      </div>
    </div>
    <div v-if="typeDetail === 'apartment'">
      <div style="position:relative;">
        <img class="img-apartment" :src="honorDetail?.image" alt="apartment-image" />
        <div class="apartment-action">
          <ActionComponent :post="honorDetail" type-post="apartment"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionComponent from "@/views/components/honors/ActionComponent.vue";

export default {
  components: {ActionComponent},
  props: {
    honorDetail: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
      required: true,
    },
    typeDetail: {
      type: String,
      default: 'apartment'
    }
  },
  data() {
    return {
      type: null,
      action: {}
    }
  },
  mounted() {
    this.type = this.$route.query.type;
  },
  methods: {
    redirectHomePage() {
      this.$router.push({
        name: 'honor-private'
      })
    },
  }
}
</script>

<style lang="css" scoped>
.btn-back {
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  font-size: 20px;
  margin-bottom: 60px;
}
.name-custom {
  color: #FCB813;
  font-size: 32px;
}
.desc-custom {
  color: white;
  font-size: 20px;
}
.img-staff-container {
  background-image: url("../../../assets/img/rounded-image.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 250px;
  position: relative;
  overflow: hidden;
  width: 300px;
  height: 200px;
}
.img-apartment {
  object-fit: cover;
  width: 100%;
  height: 400px;
  border-radius: 45px;
  margin-top: 30px;
}
.apartment-action {
  position: absolute;
  left: 80px;
  bottom: 40px;
}
.img-staff {
  position: absolute;
  border-radius: 100%;
  width: 148px;
  height: 148px;
  background-size: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}
.header-detail {
  background-image: linear-gradient(to right, #6050AF, #56479F, #473986);
  border-radius: 40px;
  padding: 20px 100px;
}
.header-okr {
  height: 200px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 40px;
  overflow: hidden;
  width: 100%;
}
.header-okr__left {
  background: #473986;
  padding-top: 20px;
  padding-left: 80px;
  padding-bottom: 20px
}
.header-blur {
  background-image: linear-gradient(to right, #473986, rgba(255,0,0,0));
  width: 200px;
  height: 200px;
  position: absolute;
  left: 0;
  top: 0;
}
.img-okr {
  object-fit: cover;
  height: 200px;
  width: 100%;
  object-position: center center;
}
.header-right {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .btn-back span {
    display: none;
  }
  .name-custom {
    color: #FCB813;
    font-size: 32px;
  }
  .header-detail {
    overflow: hidden;
    padding: 20px;
  }
  .header-okr__left {
    padding: 20px;
  }
  .img-staff-container {
    background-size: 130px;
    width: 160px;
    height: 120px;
  }
  .img-staff {
    width: 80px;
    height: 80px;
  }
  .header-detail {
    background-image: linear-gradient(to right, #6050AF, #56479F, #473986);
    border-radius: 40px;
    padding: 20px 100px;
  }
  .header-okr {
    height: 200px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 40px;
    overflow: hidden;
    width: 100%;
  }
  .header-okr__left {
    background: #473986;
    padding-top: 20px;
    padding-left: 80px;
    padding-bottom: 20px
  }
  .header-blur {
    background-image: linear-gradient(to right, #473986, rgba(255,0,0,0));
    width: 200px;
    height: 200px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .img-okr {
    object-fit: cover;
    height: 200px;
    width: 100%;
    object-position: center center;
  }
  @media only screen and (max-width: 767px) {
    .btn-back span {
      display: none;
    }
    .header-okr {
      height: 160px;
    }
    .header-detail {
      padding: 20px;
    }
    .header-okr__left {
      padding: 20px;
    }
    .img-staff-container {
      background-size: 130px;
      width: 100px;
      height: 100px;
    }
    .img-staff {
      width: 78px;
      height: 78px;
    }
    .header-detail, .header-okr{
      border-radius: 20px;
    }
    .name-custom, .desc-custom {
      font-size: 16px;
    }
  }
  .header-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .header-detail {
    padding: 40px 50px;
  }
  .img-staff-container {
    background-size: 180px;
    min-width: 200px;
    height: 160px;
  }
  .img-staff {
    width: 105px;
    height: 105px;
  }
  .header-right {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-right: 8px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .header-detail {
    padding: 40px 50px;
  }
  .img-staff-container {
    background-size: 180px;
    min-width: 200px;
    height: 160px;
  }
  .img-staff {
    width: 105px;
    height: 105px;
  }
}
</style>